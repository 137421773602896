.web {
    display: grid;
}

.mobile {
    display: none;
}


@media (max-width: 550px) {
    .mobile {
        display: block;
    }

    .web {
        display: none;
    }

}