@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .button {
        @apply bg-blue-500 text-white font-light w-28 h-10 rounded-full text-center mb-8;
    }

    .card {
        @apply bg-white overflow-hidden shadow-md relative p-16 rounded-md ml-0 mx-0;
    }

    .hoveredItem {
        @apply bg-gray-100 rounded-lg px-2 cursor-pointer;
    }
}